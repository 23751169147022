import React from 'react';
import * as PropTypes from 'prop-types';

import Chips from '@components/shared/Chips';
import Rating from '@components/shared/Rating';

const Matter = ({ image, matter }) => (
  <div className="matter-item">
    <Rating className="matter-item__rating" iconSize={24} {...matter.rating} />
    {matter.reviews && (
      <div className="matter-item__reviews">
        <b>{matter.reviews}</b> reviews by people with {matter.condition}
      </div>
    )}
    {matter.averageTime && (
      <div className="matter-item__chips">
        <p className="matter-item__subtitle">Average time until it worked</p>
        <Chips array={matter.averageTime} getChipLabel={chip => chip.text} />
      </div>
    )}
    {matter.combinations && (
      <div className="matter-item__chips">
        <p className="matter-item__subtitle">
          Most often used in combination with
        </p>
        <Chips array={matter.combinations} getChipLabel={chip => chip.text} />
      </div>
    )}
    {matter.symptoms && (
      <div className="matter-item__chips">
        <p className="matter-item__subtitle">Main symptom it helped with</p>
        <Chips array={matter.symptoms} getChipLabel={chip => chip.text} />
      </div>
    )}
    {image && (
      <div className="matter-item__media">
        <img src={image} alt="matter-image" className="matter-item__img" />
      </div>
    )}
  </div>
);

Matter.defaultProps = {
  matter: {}
};

Matter.propTypes = {
  image: PropTypes.string,
  rating: PropTypes.object,
  matter: PropTypes.object
};

export default Matter;
