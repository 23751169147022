import React from 'react';

import Landing from '@components/Landing/Landing';
import Helpers from '@utils/Helpers';

export default function Home() {
  return <Landing />;
}

Home.getInitialProps = ({ req }) => {
  const absoluteUrl = Helpers.absoluteUrl(req);
  const ogImagePath = '/images/default-product.png';

  const metaTitle =
    'CureRate: Product & Supplement Reviews for Chronic Disease';
  const metaDescription =
    'CureRate is the first ever recommendation platform focused on people with chronic conditions. Discover product & supplement reviews, or sign up free to leave your own.';

  return {
    __meta__: {
      title: metaTitle,
      tags: {
        'og:title': metaTitle,
        'og:image': absoluteUrl.origin + ogImagePath,
        description: metaDescription
      }
    }
  };
};
