import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Banner from '@components/Landing/Banner';
import Conditions from '@components/Landing/Conditions';
import Matters from '@components/Landing/Matters';
import Goals from '@components/Landing/Goals';
import Invitation from '@components/Landing/Invitation';

const Landing = ({ user }) => (
  <div className="home-page">
    <Banner />
    <Goals />
    <Matters />
    <Conditions />
    {!user && <Invitation />}
  </div>
);

Landing.propTypes = {
  user: PropTypes.object
};

const mapStateToProps = ({ auth: { user } }) => ({
  user
});

export default connect(mapStateToProps)(Landing);
