import React, { useState } from 'react';
import classNames from 'classnames';
import Typist from 'react-typist';

import Title from '@components/shared/Title';
import Blob from '@components/shared/Blob';
import Matter from '@components/Landing/Matters/Matter';
import Text from '@components/shared/Text';

const keys = ['Psoriasis', 'Ulcerative Colitis', 'PCOS', 'IBS'];

const Matters = () => {
  const [typistKey, setTypistKey] = useState(0);
  const [matters, setMatters] = useState([
    {
      condition: keys[0],
      reviews: '350',
      rating: {
        value: 4,
        name: 'psoriasis'
      },
      averageTime: [{ text: 'Immediatly' }],
      combinations: [{ text: 'Vitamin D' }],
      symptoms: [{ text: 'Itchiness' }],
      active: true
    },
    {
      condition: keys[1],
      reviews: '230',
      rating: {
        value: 5,
        name: 'ulcerative-colitis'
      },
      averageTime: [{ text: 'Less than a week' }],
      combinations: [{ text: 'Probiotic' }, { text: 'Gelatin' }],
      symptoms: [{ text: 'Bloating' }],
      active: false
    },
    {
      condition: keys[2],
      reviews: '505',
      rating: {
        value: 3,
        name: 'pcos'
      },
      averageTime: [{ text: 'A month or so' }],
      combinations: [{ text: 'Vitex' }],
      symptoms: [{ text: 'Weight gain' }],
      active: false
    },
    {
      condition: keys[3],
      reviews: '145',
      rating: {
        value: 5,
        name: 'ibs'
      },
      averageTime: [{ text: 'A couple of weeks' }],
      combinations: [
        { text: 'Kefir' },
        { text: 'Tumeric' },
        { text: 'Ginger' }
      ],
      symptoms: [{ text: 'Cramping' }],
      active: false
    }
  ]);

  return (
    <div className="matters">
      <div className="container">
        <div className="matters__heading">
          <Title center size="md">
            The first-ever recommendation platform focused on people with
            chronic conditions
          </Title>
          <Text className="matters__heading-text">
            One review made by someone who shares your challenges is worth more
            than 1000s from people who don’t
          </Text>
        </div>
        <div className="row jc-center-xxs">
          <div className="col-xxs-12 col-md-2 col-lg-4">
            <div className="matters__media">
              <img
                src="/images/men-with-loupe.svg"
                alt="men-with-loupe"
                className="matters__img"
              />
            </div>
          </div>
          <div className="col-xxs-12 col-md-5 col-lg-4">
            <div className="matters__item matters__item--left">
              <Title className="matters__title" heading="h3" size="sm">
                Same product,
                <br />
                different reviews.
              </Title>
              <Title className="matters__title" heading="h3" size="sm">
                For{' '}
                <Blob color="gray" type="1">
                  <Typist
                    key={typistKey}
                    avgTypingDelay={100}
                    className="matters__typist"
                    onTypingDone={() => setTypistKey(typistKey + 1)}
                    onLineTyped={word => {
                      if (!keys.includes(word)) {
                        const index = matters.findIndex(i => i.active);
                        const newIndex =
                          matters.length - 1 !== index ? index + 1 : 0;

                        setMatters(
                          matters.map((item, i) => {
                            item.active = i === newIndex;
                            return item;
                          })
                        );
                      }
                    }}
                  >
                    {matters.map(matter => (
                      <span key={matter.condition}>
                        {matter.condition}
                        <Typist.Backspace
                          delay={3000}
                          count={matter.condition.length}
                        />
                      </span>
                    ))}
                  </Typist>
                </Blob>
              </Title>
              <div className="matters__animation">
                {matters.map(matter => (
                  <div
                    key={matter.condition}
                    className={classNames('matters__animate', {
                      active: matter.active
                    })}
                  >
                    <Matter
                      key={matter.condition}
                      matter={matter}
                      image="./images/comment-md.svg"
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Matters;
