import React from 'react';
import * as PropTypes from 'prop-types';
import classNames from 'classnames';

const Blob = ({ type, color, className, children }) => (
  <span
    className={classNames(
      'blob',
      {
        [`blob--${color}`]: color,
        [`blob--type-${type}`]: type
      },
      className
    )}
  >
    <span className="blob__content">{children}</span>
  </span>
);

Blob.propTypes = {
  type: PropTypes.oneOf(['1', '2', '3']).isRequired,
  color: PropTypes.oneOf(['yellow', 'blue', 'gray']).isRequired,
  children: PropTypes.any,
  className: PropTypes.string
};

export default Blob;
