import React from 'react';
import PropTypes from 'prop-types';
import orderBy from 'lodash/orderBy';
import { connect } from 'react-redux';

import Title from '@components/shared/Title';
import Blob from '@components/shared/Blob';
import Text from '@components/shared/Text';
import ConditionItem from '@components/Landing/Conditions/ConditionItem';

const Conditions = ({ conditionsList }) => (
  <div className="conditions">
    <div className="container">
      <div className="conditions__header">
        <Title className="conditions__title" heading="h2" size="md">
          That feeling you get when you find something that{' '}
          <Blob color="gray" type="2">
            finally works
          </Blob>
          .
        </Title>
        <Text className="conditions__text">
          Discover supplements, products and recipes to help manage your
          condition. Or, rate and review products to help others just like you!
        </Text>
      </div>

      <div className="conditions__list">
        {[...orderBy(conditionsList, ['order'], ['asc']), null].map(
          (condition, i) => (
            <ConditionItem
              key={condition ? condition.name : i}
              condition={condition}
              className="conditions__item"
            />
          )
        )}
      </div>
    </div>
  </div>
);

Conditions.propTypes = {
  conditionsList: PropTypes.array
};

const mapStateToProps = ({ conditions: { conditionsList } }) => ({
  conditionsList
});

export default connect(mapStateToProps)(Conditions);
