import React from 'react';

import Title from '@components/shared/Title';
import Stroke from '@components/shared/Stroke';
import Goal from '@components/Landing/Goals/Goal';
import Underline from '@components/shared/Underline';

const items = [
  {
    title: (
      <>
        Last we checked,
        <br />
        Chronic doesn’t mean alone
      </>
    ),
    image: './images/light-bulb.svg',
    text: (
      <span>
        We&apos;ve scoured forums, facebook groups, and the web and curated
        products based on <Underline type="1">real recommendations</Underline>.
        Why? <strong>Because we too have chronic illnesses</strong> and
        we&apos;re not about add &quot;fatigue from researching&quot; to our
        list of symptoms
      </span>
    )
  },
  {
    title: 'And It’ll Only Get Better',
    image: './images/many-likes.svg',
    text: (
      <span>
        <strong>
          All that&apos;s left is for you to enjoy (and pitch in).
        </strong>{' '}
        The more you share, the more we can use machine learning to suggest
        personalized recommendations.
      </span>
    )
  }
];

const Goals = () => (
  <div className="goals">
    <div className="container">
      <Title className="goals__title" heading="h2" size="xl">
        We see the world <Stroke type="2">your way</Stroke>
      </Title>
      <div className="goals__list">
        {items.map(goal => (
          <Goal
            key={goal.title}
            image={goal.image}
            title={goal.title}
            text={goal.text}
            className="goals__item"
          />
        ))}
      </div>
    </div>
  </div>
);

export default Goals;
