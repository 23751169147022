import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import * as PropTypes from 'prop-types';

import Chip from '@components/shared/Chip';

const Chips = ({
  title,
  array,
  visible,
  className,
  getChipLabel,
  emptyMessage
}) => {
  const [chips, updateChips] = useState([]);

  useEffect(() => {
    if (visible && visible < array.length) {
      updateChips([...array].splice(0, visible));
    } else {
      updateChips([...array]);
    }
  }, [array]);

  return (
    <div className={classNames('chips', className)}>
      {title && <p className="chips__title">{title}</p>}
      <div className="chips__list">
        {chips.map((chip, i) => (
          <Chip
            key={`${getChipLabel(chip)}-${i}`}
            text={getChipLabel(chip)}
            className="chips__item"
          />
        ))}
        {chips.length < array.length && (
          <Chip
            text={`+${array.length - chips.length} more`}
            className="chips__item"
          />
        )}
        {!chips.length && emptyMessage && (
          <div className="chips__empty">{emptyMessage}</div>
        )}
      </div>
    </div>
  );
};

Chips.defaultProps = {
  array: [],
  title: null,
  visible: false,
  getChipLabel: chip => chip.label
};

Chips.propTypes = {
  title: PropTypes.string,
  array: PropTypes.array,
  className: PropTypes.string,
  getChipLabel: PropTypes.func,
  visible: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  emptyMessage: PropTypes.string
};

export default Chips;
