import React from 'react';

import Title from '@components/shared/Title';
import Text from '@components/shared/Text';
import Button from '@components/shared/Button';
import { INVITATION } from '@constants/content';
import routes from '@constants/routes';

const Invitation = () => (
  <div className="invitation">
    <div className="container">
      <div className="invitation__content">
        <Title className="invitation__title" heading="h2" size="xl">
          {INVITATION.title}
        </Title>
        <Text className="invitation__text">{INVITATION.text}</Text>
        <Button
          color="dark"
          href={routes.auth.signUp}
          className="invitation__btn"
        >
          {INVITATION.button}
        </Button>
        <Text className="invitation__subtext" size="sm">
          {INVITATION.subtext}
        </Text>
      </div>
    </div>
  </div>
);

export default Invitation;
