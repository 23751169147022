import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Title from '@components/shared/Title';
import Text from '@components/shared/Text';

const Goal = ({ image, title, text, className, ...props }) => (
  <div className={classnames('goal', className)} {...props}>
    <div className="goal__content">
      <div className="goal__media">
        <img src={image} alt={title} className="goal__img" />
      </div>
      <div className="goal__description">
        <Title className="goal__title" heading="h3" size="sm">
          {title}
        </Title>
        <Text className="goal__text">{text}</Text>
      </div>
    </div>
  </div>
);

Goal.defaultProps = {
  image: null,
  className: null
};

Goal.propTypes = {
  image: PropTypes.string,
  title: PropTypes.node,
  className: PropTypes.string,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
};

export default Goal;
