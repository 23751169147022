import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Route from 'next/router';
import { connect } from 'react-redux';
import Router from 'next/router';

import Title from '@components/shared/Title';
import Blob from '@components/shared/Blob';
import Stroke from '@components/shared/Stroke';
import BubbleSelect from '@components/shared/BubbleSelect';
import { cureTypes } from '@constants/common';
import routes from '@constants/routes';
import Icon from '@components/shared/Icon';

const Banner = ({ typesList, conditionsList }) => {
  const [type, updateType] = useState(typesList[0]);

  const handleChangeType = type => {
    updateType(type);
  };

  const handleChangeCondition = condition => {
    if (condition && !condition.slug) {
      Router.push(routes.contactUs);
      return;
    }
    if (type.entry !== cureTypes.combination) {
      Route.push(
        routes.cures.list.pattern,
        routes.cures.list.path(condition.slug, type.slug)
      );
    } else {
      Route.push(
        routes.combinations.list.pattern,
        routes.combinations.list.path(condition.slug)
      );
    }
  };

  const conditionOptions = [
    ...conditionsList,
    {
      id: null,
      name: (
        <strong className="condition--contact-us">
          <Icon icon="plus-slim" />
          <span>Suggest Condition</span>
        </strong>
      )
    }
  ];

  return (
    <div className="banner">
      <div className="container">
        <div className="row">
          <div className="col-xxs-12 col-md-7">
            <div className="banner__content">
              <Title className="banner__title" heading="h1" size="xl">
                Ratings & Reviews from <Stroke type="1">people</Stroke> who
                share the{' '}
                <Blob color="yellow" type="1">
                  same challenges
                </Blob>{' '}
                as you
              </Title>
              <div className="banner__text">
                I want to see CureRated
                <div className="banner__choose">
                  <div className="banner__select">
                    <BubbleSelect
                      color="blue"
                      options={typesList}
                      defaultValue={typesList[0]}
                      getOptionLabel={option => option.title}
                      getOptionValue={option => option.slug}
                      instanceId="banner-types"
                      placeholder={false}
                      onChange={handleChangeType}
                    />
                  </div>
                  for
                  <div className="banner__select">
                    <BubbleSelect
                      color="blue"
                      options={conditionOptions}
                      getOptionLabel={option => option.name}
                      getOptionValue={option => option.slug}
                      instanceId="banner-conditions"
                      placeholder="Select Condition"
                      onChange={handleChangeCondition}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xxs-12 col-md-5">
            <div className="banner__media">
              <img
                alt="banner-img"
                className="banner__img"
                src="/images/banner-art.svg"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Banner.propTypes = {
  typesList: PropTypes.array,
  conditionsList: PropTypes.array
};

const mapStateToProps = ({
  cures: { typesList },
  conditions: { conditionsList }
}) => ({
  typesList,
  conditionsList
});

export default connect(mapStateToProps)(Banner);
