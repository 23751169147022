import React from 'react';
import classNames from 'classnames';
import * as PropTypes from 'prop-types';

import Helpers from '@utils/Helpers';

const maxChipLength = 170;

const Chip = ({ text, onClick, className }) => (
  <div
    className={classNames('chip', className)}
    onClick={onClick || void 0}
    title={text}
  >
    {Helpers.cutString(text, maxChipLength)}
  </div>
);

Chip.propTypes = {
  text: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string
};

export default Chip;
