import React from 'react';
import * as PropTypes from 'prop-types';
import classNames from 'classnames';

const Underline = ({ type, className, children }) => (
  <span
    className={classNames(
      'underline',
      {
        [`underline--type-${type}`]: type // 1, 2, 3
      },
      className
    )}
  >
    {children}
  </span>
);

Underline.propTypes = {
  type: PropTypes.string,
  children: PropTypes.any,
  className: PropTypes.string
};

export default Underline;
