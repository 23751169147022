import React from 'react';
import NextLink from 'next/link';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Title from '@components/shared/Title';
import Text from '@components/shared/Text';
import Icon from '@components/shared/Icon';
import Stroke from '@components/shared/Stroke';
import routes from '@constants/routes';

const ConditionItem = ({ condition, className, ...props }) => {
  const href = condition && routes.conditions.details.path(condition.slug);
  const Element = href ? 'a' : 'div';

  const Component = (
    <Element
      className={classnames(
        'condition-item',
        {
          'condition-item--empty': !condition
        },
        className
      )}
      {...props}
    >
      {(condition && (
        <>
          <div className="condition-item__content">
            <Title className="condition-item__title" heading="h3" size="sm">
              {condition.name}
            </Title>
            <div className="condition-item__description">
              <Text className="condition-item__text" color="gray">
                Explore condition hub
              </Text>
              <Icon className="condition-item__arrow" icon="angle-right" />
            </div>
          </div>
          <div className="condition-item__media">
            <img
              className="condition-item__img"
              src={condition.file}
              alt={condition.name}
            />
          </div>
        </>
      )) || (
        <div className="condition-item__empty">
          Others <Stroke type="3">coming soon!</Stroke>
        </div>
      )}
    </Element>
  );

  if (href) {
    const linkHref = routes.conditions.details.pattern;
    return (
      <NextLink href={linkHref} as={href}>
        {Component}
      </NextLink>
    );
  }

  return Component;
};

ConditionItem.defaultProps = {
  condition: null,
  className: null
};

ConditionItem.propTypes = {
  condition: PropTypes.object,
  className: PropTypes.string
};

export default ConditionItem;
