import React from 'react';
import * as PropTypes from 'prop-types';
import classNames from 'classnames';

const Stroke = ({ type, className, children }) => (
  <span
    className={classNames(
      'stroke',
      {
        [`stroke--type-${type}`]: type
      },
      className
    )}
  >
    <span className="stroke__content">{children}</span>
  </span>
);

Stroke.propTypes = {
  type: PropTypes.oneOf(['1', '2', '3', '4']).isRequired,
  children: PropTypes.any,
  className: PropTypes.string
};

export default Stroke;
